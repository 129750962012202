import { PUBLIC_ENVIRONMENT, PUBLIC_SENTRY_DSN } from '$env/static/public';
export const appConfig = {
    baseUrl: 'https://integrations-inky.vercel.app/',
    longTasksBaseUrl: 'https://iceberg-integrations.onrender.com/',
    sentryDsn: PUBLIC_SENTRY_DSN,
    smartLeadCampaigns: {
        electricianPlaintext: 213443,
        electricianGuessingContactPlaintext: 229199,
        mentalHealthTherapistPlaintext: 213142,
        mentalHealthTherapistGuessingContactPlaintext: 229950,
        radtechPlaintext: 211824,
        radtechGuessingContactPlaintext: 229930,
        physicalTherapistPlaintext: 469044,
    },
    environment: PUBLIC_ENVIRONMENT || 'development',
};
