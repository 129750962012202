import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';
import { appConfig } from "./app.config";

Sentry.init({
  dsn: appConfig.sentryDsn,
  environment: appConfig.environment,
  tracesSampleRate: 0.05,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
